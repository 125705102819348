import accountdashboard from "../../assets/img/accountdashboard.jpg";
import upliftsales from "../../assets/img/upliftsales.jpg";

const Mam = () => {
  return (
    <div class="container-fluid">
      <div class="row row-content domain-expert faq-section ecom-account-mgmt">
        <div class="col-12 text-center p-2 py-3" id="discount-block">
          <h2>
            We Are A Trusted E-Commerce Marketplace Account Management Firm
          </h2>

          <h5>
            Lebenswerk Consulting always ensures our clients get professional
            e-commerce marketplace account management services.
          </h5>
        </div>
        <div class="col-12 py-2 text-center">
          <h3>
            Need For E-Commerce Marketplace Account Management Services Provider
          </h3>
          <div className="faq-grid ams-grid">
            <div class="grid_row" id="main-container">
              <div class="grid_item" id="test-box">
                <span class="grid_text">
                  ➢ To Strengthen Online Business Presence
                </span>
              </div>
              <div class="grid_item" id="test-box">
                <span class="grid_text">➢ To Introduce New Products</span>
              </div>
              <div class="grid_item" id="test-box">
                <span class="grid_text">
                  ➢ To Get Expertise Of Different Marketplaces
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-content ams-section">
        <div class="col-12 text-center p-2">
          <h2>Key E-Commerce Platforms We Work With</h2>
          <a href="#reserve" class="btn btn-lg m-2 ">
            Amazon
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            Flipkart
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            Myntra
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            Snapdeal
          </a>
          <a href="#reserve" class="btn btn-lg m-2 ">
            Nykaa
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            Ajio
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            Tata Cliq
          </a>
          <a href="#reserve" class="btn btn-lg m-2 ">
            Meesho
          </a>
          <a href="#reserve" class="btn btn-lg m-2 ">
            JioMart
          </a>
          <a href="#reserve" class="btn btn-lg m-2 ">
            Tata 1mg
          </a>
          <a href="#reserve" class="btn btn-lg m-2 ">
            Netmeds
          </a>
          <a href="#reserve" class="btn btn-lg m-2 ">
            Pharmeasy
          </a>
          <a href="#reserve" class="btn btn-lg m-2 ">
            Hopscotch
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            FirstCry
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            Pepperfry
          </a>
          <a href="#reserve" class="btn btn-lg m-2 ">
            Udaan
          </a>
          <a href="#reserve" class="btn btn-lg m-2 ">
            Urban Ladder
          </a>
          <a href="#reserve" class="btn btn-lg m-2 ">
            Bijnis
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            Etsy
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            Fynd
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            Walmart
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            Gem
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ShopClues
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            Limeroad
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            Shopsy
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            Glowroad
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            Aza fashion
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            Ebay
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            Solv
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            Uniket
          </a>
        </div>
      </div>
      <div class="row row-content ams-section2">
        <div class="col-12 text-center p-2">
          <h2>Our E-Commerce Marketplace Account Management Services </h2>
          <h5>
            You know the best for your business. But, when the online ecosystem
            has undergone a massive change, a professional hand will dynamically
            improve your sales revenue.
          </h5>
        </div>

        <div class="col-sm-6 deals my-auto" id="reservebtn">
          <h3 class=""> Account Registration & Set-Up</h3>
          <p class="mt-2">
            Our e-commerce experts have handled account set-up on open and
            closed e-commerce platforms across different industries. We assist
            our clients in launching their online business on portals like
            Amazon, Nykaa, Flipkart, Tata Cliq, Ajio, and Myntra, among many
            other renowned platforms. Our team knows the ins and outs of account
            and trademark registry, document verification, and brand
            establishment.
          </p>
          <h3 class="">Product Listing & A+ Catalog Designing</h3>
          <p class="mt-2">
            We’ll prepare well-optimized product listings for you that can get
            discovered with ease whenever your prospective buyers look for
            similar products. Our designers develop an eye-catching catalog for
            your products. For this, we conduct extensive keyword research and
            use advanced SEO tools to filter the best ones for your products and
            business.
          </p>
          <h3 class=""> Account & Dashboard Management</h3>
          <p class="mt-2">
            Account management is a complex task but with our experts, several
            business owners have become a pro at it. Our qualified and
            experienced e-commerce experts will train you for operating your
            e-commerce business and provide every assistance. We will walk you
            through all the aspects of online business management.
          </p>
          <h3 class="">Competitive Pricing Solutions</h3>
          <p class="mt-2">
            Our team researches the market, analyses the competition, and
            creates a competitive pricing strategy that assures at-par sales,
            with profits. With us, you’ll generate optimum profits and retain
            your prospective buyers. We assist you in developing the best
            pricing policy for your products that will increase your product
            demand and fetch a broad market share for your brand.
          </p>
        </div>

        <div class="col-sm-6 p-2">
          <div class="row">
            <div class="col p-5">
              <img
                src={accountdashboard}
                alt="Marketplace Account Management"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
        <br />

        <div class="col-sm-6 p-2">
          <div class="row d-none d-sm-block">
            <div class="col p-5">
              <img
                src={upliftsales}
                alt="Marketplace Account Management"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
        <div class="col-sm-6 deals my-auto" id="reservebtn">
          <h3 class="">Strategic Advertising Campaigns</h3>
          <p class="mt-2">
            Our team of experienced e-commerce marketplace account managers is
            well aware of performance marketing techniques that will get your
            business in the limelight. Our team picks the appropriate and
            trending keywords and integrates the same with the product page
            content. This creates optimized product descriptions, which enhances
            brand value.
          </p>

          <h3 class="">Uplift Sales</h3>
          <p class="mt-2">
            We are a trusted name in the industry for driving sales for our
            clients. Our team has years of experience in e-commerce account
            handling and paid advertising. We lay focus on improving your
            product rankings, designing customized sponsored ad campaigns,
            working on getting positive reviews as well as ratings, and boosting
            your business profile.
          </p>
          <h3 class="">Inventory Management</h3>
          <p class="mt-2">
            We provide real-time inventory checks and allot SKUs to your
            products so that your e-commerce business never runs out of stock.
            Our team provides you with meaningful insights on fulfilment
            programs and documentation. For managing your orders, we have a
            proper system and advanced tools in place to assist you in shipment
            and returns.
          </p>

          <h3 class="">Safeguard Account</h3>
          <p class="mt-2">
            We work in accordance with portal algorithms to protect your account
            from any kind of suspension threats. Our team members will also
            guide you about the crucial aspects of trademark copyrights so that
            your business identity won’t face any problems. Therefore, we ensure
            the smooth and hassle-free functioning of your business.
          </p>
          <h3 class=""> Financial Reporting</h3>
          <p class="mt-2">
            We provide you with profit and loss evaluation because it is crucial
            to see the actual growth in business. Taking all the components such
            as fees and tax deductions into account, we ensure that you get a
            clear picture of your profits. Our approach remains transparent
            throughout and results are 100% accurate.
          </p>
        </div>

        <br />
        <div className="text-center ">
          <h5>Get Your Business Noticed Online With Lebenswerk Consulting!</h5>
        </div>
      </div>
    </div>
  );
};

export default Mam;
