import React, { useState } from "react";

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      {/* <div className="accordion-item">
        <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
          <div>{title}</div>
          <div>{isActive ? "-" : "+"}</div>
        </div>
        {isActive && <div className="accordion-content">{content}</div>}
      </div> */}

      <div class="grid_row" id="main-container">
        <div
          class="grid_item"
          id="test-box"
          onClick={() => setIsActive(!isActive)}
        >
          <span class="grid_text">{title}</span>
          <div class="grid_img">
            {/* <img src={sale} class="img-fluid" /> */}
            <div>{isActive ? "-" : "+"}</div>
          </div>
        </div>
      </div>

      {isActive && (
        <div className="accordion-content">
          <div>{content}</div>
        </div>
      )}
    </>
  );
};

export default Accordion;
