import boostsales from "../../assets/img/boostsales.jpg";
import productlisting from "../../assets/img/productlisting.jpg";
import coupons from "../../assets/img/coupons.png";

const Ams = () => {
  return (
    <div class="container-fluid">
      <div class="row row-content ams-section ecom-ams">
        <div class="col-12 text-center p-2">
          <h2>
            Our E-Commerce Account Management Services To Trend Your Business
            Online!
          </h2>
          <h5>
            Lebenswerk Consulting is an e-commerce account management service
            provider in Delhi NCR to ensure your online store functions
            smoothly. It is our job to publicize your business and get it to
            towering heights with our services. We, at Lebenswerk Consulting,
            help your brand maintain a credible online presence in the
            marketplace. Our expertise in the market is pursued from multiple
            domains. Trust our experts for-
          </h5>
          <a href="#reserve" class="btn btn-lg m-2 ">
            ➢ Your E-Commerce Store Management
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ➢ Increasing Sales Revenue
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ➢ Highlighting Your Brand Value
          </a>
        </div>
      </div>
      <div class="row row-content ams-section2">
        <div class="col-12 text-center p-2">
          <h2>Why Do You Need Our E-Commerce Account Management Services?</h2>
          <h5>
            You, as a business entity in no way, wish to remain behind. We
            welcome you to join the best. To witness continuous growth in the
            online marketplace, you will require professional assistance from an
            e-commerce account management services provider for:-
          </h5>
        </div>

        <div class="col-sm-6 deals my-auto" id="reservebtn">
          <h3 class="">Brand Registry</h3>

          <p class="mt-2">
            Through brand and trademark registration, we establish your
            uniqueness in the online marketplace. We work on brand registry,
            document verifications, and category and label approvals. This will
            give you rights in your product listings. It’ll also ensure the
            safety of your trademark and registration number which will
            safeguard you from any kind of brand infringement. We’ll guide you
            about your copyrights and the possible solutions as well.
          </p>

          <h3 class="">Brand Store Creation</h3>

          <p class="mt-2">
            Brand store creation and account set-up can seem to be difficult
            tasks for new sellers. Lebenswerk Consulting is here to get you past
            these troublesome tasks and manage end-to-end processes and work on
            request submissions. We can assist you with both open and closed
            onboarding and registration services on platforms like Amazon,
            Flipkart, Nykaa, Ajio, Myntra, Paytm Mall, Tata Cliq, Udaan, and
            many more. .
          </p>
          <h3 class="">Product Listings</h3>

          <p class="mt-2">
            Product listing is a crucial element of any e-commerce site and we
            can do it better for you. We are an e-commerce account management
            services provider in Delhi NCR that researches the best keywords and
            categories that will highlight your products in the marketplace.
            With us, you’ll get optimized and enriched product listings that
            will showcase your Product USPs, varieties, sizes, colors, etc. Our
            expert team takes care of the portal-specific guidelines.
          </p>

          <h3 class="">A+ Cataloguing</h3>

          <p class="mt-2">
            We’ll ensure that your product copies and catalogs get a boost with
            A+ Content so that they can attain higher rankings. Our team
            utilizes our best resources to get the most suitable keywords and
            reverse ASIN keywords as well. We will prepare an eye-catching
            catalog for your product that will encourage your prospective buyers
            to be your actual buyers.
          </p>
        </div>

        <div class="col-sm-6 p-2">
          <div class="row">
            <div class="col p-5">
              <img
                src={boostsales}
                alt="Account Management Service"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
        <br />

        <div class="col-sm-6 p-2">
          <div class="row d-none d-sm-block">
            <div class="col p-5">
              <img
                src={productlisting}
                alt="Account Management Service"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
        <div class="col-sm-6 deals my-auto" id="reservebtn">
          <h3 class="">Sponsored Advertisement & Campaign Management</h3>

          <p class="mt-2">
            We are an experienced e-commerce service provider in Delhi NCR that
            implements goal-oriented strategies for boosting your product
            visibility. Our team knows the ins and outs of various marketing
            channels. We design customized ad campaigns after analyzing your
            business requirements and goals. Our performance marketing campaigns
            target specific audiences with appropriate keywords. It ensures that
            your product listing appears when a prospective buyer searches for
            it online.
          </p>

          <h3 class="">Boost Sales Deals</h3>

          <p class="mt-2">
            For boosting your sales revenue as an online seller, e-commerce
            store owners need to focus on sales-driven strategies. With hands-on
            experience in different industries we know how to multiply sales for
            our clients. We formulate sales deals strategies like ‘limited time
            offer,’ or ‘deal of the day,’ from time to time so that you can get
            more sales. We have assisted many store owners to take their sales
            to a whole another level.
          </p>

          <h3 class="">Prime/F-Assured Implementation</h3>

          <p class="mt-2">
            Order processing requires expert supervision and alignment of
            certain activities that ensure timely delivery. Our experts will
            guide you about the management of physical picking stations and IT
            infrastructure. Certain platforms like Amazon and Flipkart also
            provide Fulfillment Services that facilitate inventory storage,
            product packaging, shipment, and returns. We will help you
            understand the eligibility criteria and terms of the contract to
            work out on such options as well.
          </p>

          <h3 class="">Account Protection</h3>

          <p class="mt-2">
            Maintaining your e-commerce account's health is crucial to ensure
            its survival in the marketplace. We work on every aspect to keep
            your e-commerce account’s health intact. Our team attentively looks
            after inventory management, order management, seller rankings,
            product pricing, and reviews and ratings. This ensures smooth
            continual of your store operations and safeguards your e-commerce
            account from any kind of suspension strikes.
          </p>
        </div>
        <br />

        <div class="col-sm-6 deals my-auto" id="reservebtn">
          <h3 class=""> Seller Reinstatement</h3>

          <p class="mt-2">
            We provide specialized reinstatement services for suspended seller
            accounts. Our expert e-commerce managers dive deeper into
            identifying the root cause that got you into this problem. After
            that, we quickly formulate an action plan to resolve the issue once
            and for all and implement that. We have helped several sellers with
            our on-the-spot strategies in recovering their suspended accounts.
          </p>

          <h3 class="">Coupons & Promotions</h3>

          <p class="mt-2">
            We facilitate coupon discounts and promotional discounts on
            different categories, brands, orders, etc. It encourages the
            prospective buyer to make a purchase, increases conversions, and
            gives a competitive edge over others. Our team supports you
            throughout in handling the sales funnel with different brand offers.
            We also suggest you advanced tools and develop a plan of action to
            manage such orders efficiently.
          </p>
          <h3 class="">Sell Global</h3>

          <p class="mt-2">
            Our e-commerce account management services also cover operations
            related to overseas selling. We assist in securing the required
            import and export certifications, AD Code registration, payment
            gateway integration, DHL/FedEx shipping, and other related services.
            With us, you can expect all solutions in a single place and expert
            guidance related to documentation and other areas of overseas order
            processing.
          </p>

          <h3 class="">Financial Assessments</h3>

          <p class="mt-2">
            With our team of qualified account managers, you’ll get accurate
            investment and revenue figures. We apply for applicable tax benefits
            and maintain the authenticity of transactions so that your brand
            name remains trusted. Our team will share financial reports and
            profit and loss analysis with you so that you can also track your
            ROI and our performance.
          </p>
        </div>

        <div class="col-sm-6 p-2">
          <div class="row">
            <div class="col p-5">
              <img
                src={coupons}
                alt="Account Management Service"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row row-content domain-expert faq-section">
        <div class="col-12 text-center p-2 py-3" id="discount-block">
          <h2>Why Choose Lebenswerk Consulting For Your E-Commerce Store?</h2>
          <h5>
            As a credible e-commerce account management services provider, we at
            Lebenswerk Consulting provide you with-{" "}
          </h5>
        </div>

        <div class="col-12 py-2">
          <div className="faq-grid ams-grid">
            <div class="grid_row" id="main-container">
              <div class="grid_item" id="test-box">
                <span class="grid_text">➢ Strategic Approach</span>
              </div>
              <div class="grid_item" id="test-box">
                <span class="grid_text">➢ Experienced Insights</span>
              </div>
              <div class="grid_item" id="test-box">
                <span class="grid_text">➢ Exceptional Customer Support</span>
              </div>
              <div class="grid_item" id="test-box">
                <span class="grid_text">➢ End-To-End Assistance Support</span>
              </div>
              <div class="grid_item" id="test-box">
                <span class="grid_text">➢ B2B & B2C Vertical Management</span>
              </div>
            </div>
          </div>

          <div className="text-center ">
            <h5>Let’s Take Your Business To New Heights!</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ams;
