import midhiring from "../../assets/img/midhiring.jpg";
import midmanagement from "../../assets/img/midmanagement.jpg";

const Mrc = () => {
  return (
    <div class="container-fluid">
      <div class="row row-content ams-section">
        <div class="col-12 text-center p-2">
          <h2>
            Lebenswerk Consulting - Best Mid Level Management Recruitment
            Consultants In India
          </h2>
          <h5>
            Our mid level management recruitment consultants provide a highly
            competitive, skilled and expert team to handle your business.
          </h5>
          <h2>Importance Of Mid Level Management Staff</h2>
          <h5>
            Middle level management plays an important role in the overall
            hierarchy of an organization and fulfills the crucial responsibility
            of leading a team and assisting top level executives. Executives
            working at the middle level in an organization are usually:-
          </h5>

          <a href="#reserve" class="btn btn-lg m-2 ">
            ● Chief Supervisors
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ● Department Heads
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ● Functional Heads
          </a>
          <a href="#reserve" class="btn btn-lg m-2 ">
            ● Branch Managers
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ● Sales Managers
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ● Regional Directors
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ● Programming Managers
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ● Operation Managers
          </a>

          <h5>
            Mid level executives must possess- technical skills, communication
            skills, persuasion skills, and negotiation skills. They should have
            expertise in both carrying out the business processes and managing a
            team. They should be not only hard-working employees but also
            effective leaders.
          </h5>
        </div>
      </div>

      <div class="row row-content  ams-section mrc-section">
        <div class="col-12 text-center p-2 mrc-content">
          <h2>Why Choose Our Mid Level Management Recruitment Consultants?</h2>
          <h5>
            We have worked with business firms of all sizes and types and are
            the best resort for mid level recruitment because we provide:-
          </h5>

          <div class="col-12 text-center p-2">
            <a href="#reserve" class="btn btn-lg m-2 ">
              ➢ Reliable Hiring Networks
            </a>
            <a href="#reserve" class="btn btn-lg m-2">
              ➢ Strict Benchmarks
            </a>
            <a href="#reserve" class="btn btn-lg m-2">
              ➢ Transparent Approach
            </a>
            <a href="#reserve" class="btn btn-lg m-2 ">
              ➢ Exceptional Talent
            </a>
            <a href="#reserve" class="btn btn-lg m-2">
              ➢ End-To-End Assistance
            </a>
          </div>
        </div>
      </div>

      <div class="row row-content ams-section2">
        <div class="col-12 text-center p-2">
          <h2>Our Services As Mid Level Management Recruitment Consultants </h2>
          <h5>
            At Lebenswerk Consulting, we leave no stone unturned in sourcing the
            most capable, efficient, honest, and dedicated mid level executives
            for your organization:-
          </h5>
        </div>

        <div class="col-sm-6 deals my-auto" id="reservebtn">
          <h3 class="">Understand Work Culture & KRAs</h3>

          <p class="mt-2">
            Our team of qualified expert recruitment consultants identify the
            unique requirements of your business and accordingly draw a suitable
            plan to find the bestest talent. We can also assist in forming
            policies regarding compensation, leave, health and insurance,
            overtime, resignation, and more. They keep in mind to be selective
            during the talent acquisition process and choose candidates who can
            not only perform the job duties well but also adapt to the company's
            work culture.
          </p>

          <h3 class=""> Talent Hunt Drive</h3>

          <p class="mt-2">
            Based on the specifications provided by you, our team conducts
            talent hunt drives and connects with those who possess the requisite
            leadership qualities and qualifications. Managers, department heads,
            supervisors, and other executives working at the middle level affect
            the success and growth of a business firm. Therefore, we use the
            latest technology, reliable networks and appropriate techniques at
            every step of the recruitment process.
          </p>

          <h3 class="">Conduct Interviews & Tests</h3>

          <p class="mt-2">
            Our team consists of personnel who have valuable experience in
            recruiting executives for middle level management in different types
            of industries. We evaluate every applicant on the basis of
            qualifications, skills, personality traits, and other criteria.
            Along with the required technical expertise and experience, our team
            of qualified mid level management recruitment consultants also
            ensures to onboard such employees who are self-driven and decisive.
          </p>
        </div>

        <div class="col-sm-6 p-2">
          <div class="row">
            <div class="col p-5">
              <img
                src={midhiring}
                alt="recruitment consultants"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
        <br />

        <div class="col-sm-6 p-2">
          <div class="row d-none d-sm-block">
            <div class="col p-5">
              <img
                src={midmanagement}
                alt="recruitment consultants"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
        <div class="col-sm-6 deals my-auto" id="reservebtn">
          <h3 class="">Documentation & Onboarding</h3>

          <p class="mt-2">
            After a proper assessment of relevant factors and your approval, we
            assist you with the documentation and onboarding process. Our team
            of expert mid level management recruitment consultants can even
            offer their input in creating offer letters, employee handbooks, and
            other documents. We ensure that the employee becomes familiar with
            the workflow, rules, and other elements related to your
            organization.
          </p>

          <h3 class="">Productive Work Environment</h3>

          <p class="mt-2">
            A cordial and harmonious relationship is the essence of every
            workplace. We conduct in-depth discussions with the client and
            suggest ways to build a friendly and productive work environment.
            Our team members assist you with reward and recognition programs,
            and medical plans and suggest ways to engage the mid level staff
            with top management and executives. This creates a sense of
            belongingness, improves workflow and keeps the attrition rate of
            your organization low.
          </p>

          <h3 class=""> Procedural Follow-Up</h3>

          <p class="mt-2">
            We keep a check on employee performance and utilize the right
            methods and resources to measure the employee’s contribution towards
            the organization. Our team has sourced mid level executives for a
            large number of organizations. We provide the necessary support
            during performance evaluation, feedback collection and suggest the
            best ways to work things out.
          </p>
        </div>
        <br />
      </div>
      <div class="row row-content domain-expert faq-section">
        <div class="col-12 text-center p-5" id="discount-block">
          <h2 className="pb-3">Industries We Serve</h2>
          <h5>
            Our services for middle level staff recruitment have earned us
            respect from clients across various industry verticals which
            include, but are not limited to:-
          </h5>
        </div>

        <div class="col-12 text-center px-5 pb-2 Hcs">
          <a href="#reserve" class="btn btn-lg m-2 ">
            ➢ Food
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ➢ Fashion
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ➢ Wholesale & Retail
          </a>
          <a href="#reserve" class="btn btn-lg m-2 ">
            ➢ Ecommerce
          </a>

          <a href="#reserve" class="btn btn-lg m-2">
            ➢ Professional Salon Business
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ➢ Banking & Finance
          </a>

          <a href="#reserve" class="btn btn-lg m-2 ">
            ➢ Insurance
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ➢ Law
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ➢ Real State
          </a>

          <a href="#reserve" class="btn btn-lg m-2 ">
            ➢ Education
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ➢ Health
          </a>
          <a href="#reserve" class="btn btn-lg m-2 ">
            ➢ Hospitality
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ➢Telecommunications
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ➢ Manufacturing
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ➢ Transportation
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ➢ Tourism
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ➢ Non-Profit Organizations.
          </a>
        </div>

        <div class="col-12 text-center p-5 ">
          <h5>
            Talk to Lebenswerk Consulting Mid Level Management Recruitment
            Consultants For Driving Exceptional Talent To Your Team!
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Mrc;
