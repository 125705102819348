import haveplan from "../../assets/img/haveplan.jpg";
import financeanalysis from "../../assets/img/financeanalysis.jpg";
import solution from "../../assets/img/solution.jpg";
import Accordion from "../Accordion";

const Bmc = () => {
  const accordionData = [
    {
      title: "Qualified Team",
      content:
        "Our team consists of qualified experts from top institutions including XLRI, NIIT, and FMS. We have hands-on experience in handling difficult scenarios for e-commerce businesses and providing them with optimum solutions.",
    },
    {
      title: " Industry Exposure",
      content:
        "Lebenswerk Consulting serves varied industries and understands what can benefit a particular niche. This gives us an added advantage to help businesses develop and expand their verticals. ",
    },
    {
      title: "Standardized Policies",
      content:
        "We work with industry-specific standards and ensure strict quality checks at our own policies. To be the most trusted name in the e-commerce industry, we keep an open eye for changes and trends in different industries. ",
    },
    {
      title: "Transparent Reporting",
      content:
        "We invest our efforts in measuring the effects of our strategies for you and share them in the form of quarterly and annual reports. Our transparent reporting mechanisms and results will surely impress you. ",
    },
    {
      title: "Expertise In Diverse Areas",
      content:
        "Diversity in business requirements and resource availability has aroused demand for different business strategies. Whether it is marketing or logistics, financial assessment, or product development, you’ll get suitable solutions for every single e-commerce business need from us. ",
    },
  ];
  return (
    <div class="container-fluid">
      <div class="row row-content ams-section">
        <div class="col-12 text-center p-2">
          <h2>Best E-Commerce Business Management Company In India</h2>
          <h5>
            Lebenswerk Consulting is an experienced and highly qualified team of
            professionals who provide management consulting services to
            businesses of all sizes. With a professional e-commerce business
            solutions provider by your side, handling business tasks will become
            easy beyond imagination. Our strategy development and methodological
            implementation make the workflow streamlined and smooth. Streamlined
            workflow is essential to keep a sync in the operations.
          </h5>
        </div>
      </div>
      <div class="row row-content ams-section2">
        <div class="col-12 text-center p-2">
          <h2>
            How Can We Help Your Business Grow With Our E-Commerce Business
            Management Services?
          </h2>
          <h5>
            Dealing in e-commerce business management requires much more than
            online marketing and a fancy website. Therefore, with core
            e-marketing operations, we also assist businesses in these
            management consulting operations:
          </h5>
        </div>

        <div class="col-sm-6 deals my-auto" id="reservebtn">
          <h3 class="">Distribution Expansion & Business Planning</h3>

          <p class="mt-2">
            We formulate strategies for your e-commerce business expansion
            across different verticals and assist in partnerships across
            multiple distribution channels. We deliver expertise and solutions
            on product development, promotion, price, packaging, and
            distribution.
          </p>

          <h3 class="">Employee Development & Performance Evaluation</h3>

          <p class="mt-2">
            Our team of HR Consultants finds suitable matches for e-commerce
            businesses across varied industries. We provide end-to-end solutions
            for recruiting, onboarding, training, performance monitoring,
            rewarding, and managing staff with excellence.
          </p>
          <h3 class="">Product Portfolio Management</h3>

          <p class="mt-2">
            Our team performs product portfolio analysis and suggests the key
            measures to eliminate obstacles. We also design and implement a
            go-to-market strategy for new product launches.
          </p>

          <h3 class="">Trade & Channel Engagement</h3>

          <p class="mt-2">
            Customer loyalty and retention are crucial for every business. We
            have a team of professional e-commerce experts who design
            captivating trade rewards and manage these programs, schemes, and
            redemption of tokens for different products and buyers.
          </p>
        </div>

        <div class="col-sm-6 p-2">
          <div class="row">
            <div class="col p-5">
              <img
                src={haveplan}
                alt="Management Consulting"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
        <br />

        <div class="col-sm-6 p-2">
          <div class="row d-none d-sm-block">
            <div class="col p-5">
              <img
                src={financeanalysis}
                alt="Management Consulting"
                class="img-fluid"
              />
            </div>
          </div>
        </div>

        <div class="col-sm-6 deals my-auto" id="reservebtn">
          <h3 class="">Product Development Intervention</h3>

          <p class="mt-2">
            We work upon new product development and improvisation of the
            already existing ones to match them with advancements of the time.
            Step-by-step, we perform market research, buyer requirement
            analysis, and tests before the product launch.
          </p>

          <h3 class="">SOPs & Policies Framework</h3>

          <p class="mt-2">
            We lay down policies and Standard Operating Procedures (SOPs) to
            make your e-commerce business operations smooth and monitor them
            frequently. Having a procedural plan in place will not only save
            time but will also ensure flawless functioning and coordination.
          </p>

          <h3 class="">Demand Planning & Supply Chain Management</h3>

          <p class="mt-2">
            Inventory management is one of the most crucial aspects of
            e-commerce business management. We are an experienced management
            consulting firm that forecasts accurate demand, formulates pricing
            strategy accordingly, and guides throughout logistics, warehousing
            and fulfillment processes and order management.
          </p>

          <h3 class="">Financial Analysis</h3>

          <p class="mt-2">
            From cost evaluation to financial leakages study, we comprehend
            everything for our clients and serve them with crystal clear
            results. We provide clients with financial reports at regular time
            intervals so they can check how their business is performing in the
            market. Our team also suggests necessary measures to overcome that
            problem.
          </p>
        </div>
        <br />

        {/* <div class="row  d-block d-sm-none">
          <div class="col p-5">
            {" "}
            <img src={LebenswerkFmcg} class="img-fluid" />
          </div>
        </div> */}
      </div>
      <div class="row row-content ams-section2">
        <div class="col-12 text-center p-2">
          <h2>
            Our Strategic Approach Makes Us A Reputable Management Consulting
            Firm In India
          </h2>
          <h5>
            Our unique and strategic approach has helped us grab a place among
            the best e-commerce management consulting firms in Delhi.
          </h5>
        </div>

        <div class="col-sm-6 deals my-auto" id="reservebtn">
          <h3 class="">Business Requirement Assessment</h3>

          <p class="mt-2">
            We understand that a single plan can’t be the best for every firm
            due to its diverse nature. Therefore, we first assess the individual
            business requirements and provide professional handling for various
            business areas to make the most of every opportunity.
          </p>

          <h3 class="">Problem Analysis</h3>

          <p class="mt-2">
            Our experts get started on their tasks by first identifying and
            analyzing the core of a problem. We look for factors that have
            created the troublesome situation and served as a hindrance to the
            key operations.
          </p>
          <h3 class="">Strategy Alignment</h3>

          <p class="mt-2">
            Our team immediately starts assessing various measures to get a hold
            of the problem-causing factors. With hands-on experience, we have
            readily available flexible strategies to cope with issues that act
            as obstacles to your business operations.
          </p>

          <h3 class="">Solution Implementation</h3>

          <p class="mt-2">
            After drawing a plan for resolving a specific problem, we implement
            it right away. Our team of qualified management consultants
            dedicatedly looks after the execution process and assists you
            throughout its different phases.
          </p>

          <h3 class="">Monitoring</h3>

          <p class="mt-2">
            We don’t sit back after executing our management plans. Our team
            keeps a track of every activity and measures its after-results. It
            helps us in weighing the performance improvement and making the
            necessary rectifications.
          </p>
        </div>

        <div class="col-sm-6 p-2">
          <div class="row">
            <div class="col p-5">
              <img
                src={solution}
                alt="Management Consulting"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
        <br />
      </div>
      <div class="row row-content domain-expert faq-section">
        <div class="col-12 text-center p-2 py-3" id="discount-block">
          <h2>Why Choose Us?</h2>
          <h5>
            Lebenswerk Consulting is a trusted e-commerce business management
            company that provides solutions for every area of an e-commerce
            business. We offer you:-
          </h5>
        </div>

        <div class="col-12 py-2">
          {/* <div className="faq-grid ams-grid">
            <div class="grid_row" id="main-container">
              <div class="grid_item" id="test-box">
                <span class="grid_text">➢ Strategic Approach</span>
              </div>
              <div class="grid_item" id="test-box">
                <span class="grid_text">➢ Experienced Insights</span>
              </div>
              <div class="grid_item" id="test-box">
                <span class="grid_text">➢ Exceptional Customer Support</span>
              </div>
              <div class="grid_item" id="test-box">
                <span class="grid_text">➢ End-To-End Assistance Support</span>
              </div>
              <div class="grid_item" id="test-box">
                <span class="grid_text">➢ B2B & B2C Vertical Management</span>
              </div>
            </div>
          </div> */}

          <div className="faq-grid">
            {accordionData.map(({ title, content }) => (
              <Accordion title={title} content={content} />
            ))}
          </div>
          <div className="text-center ">
            <h5>
              Get In Touch With Lebenswerk Consulting For Smooth E-Commerce
              Business Management!
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bmc;
