import React, { Component } from "react";
import Slider from "react-slick";
import paytm from "../assets/img/paytm.png";
import fynd from "../assets/img/fund1.png";
import bijnis from "../assets/img/bijnis.png";
import firstcry from "../assets/img/firstcry.png";
import snapdeal from "../assets/img/Snapdeal.png";
import myntra from "../assets/img/myntra.png";
import flipkart from "../assets/img/flipkart.png";
import meesho from "../assets/img/Meesho.jpg";
import mall91 from "../assets/img/mall91.png";
import cloudtail from "../assets/img/cloudtail.png";
import nyka from "../assets/img/nykaa.png";
import udaan from "../assets/img/udaan.jpg";
import tatacliq from "../assets/img/tata-cliq.png";
import amazon from "../assets/img/amazon.png";

export default class SliderComp extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 3000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div>
        <Slider {...settings}>
          <div className="Slidercomp align-middle" style="align-items: center;">
            <img src={mall91} alt="mall91" />
          </div>
          <div className="Slidercomp align-middle" style="align-items: center;">
            <img src={udaan} alt="udaan" />
          </div>
          <div className="Slidercomp align-middle" style="align-items: center;">
            <img src={myntra} alt="myntra" />
          </div>
          <div
            className="Slidercomp align-middle"
            style="align-items: center;"
            align="center"
          >
            <img src={paytm} alt="paytm" />
          </div>
          <div className="Slidercomp align-middle" style="align-items: center;">
            <img src={snapdeal} alt="snapdeal" />
          </div>
          <div className="Slidercomp align-middle" style="align-items: center;">
            <img src={amazon} alt="amazon" />
          </div>
          <div className="Slidercomp align-middle" style="align-items: center;">
            <img src={flipkart} alt="flipkart" />
          </div>
          <div className="Slidercomp align-middle" style="align-items: center;">
            <img src={tatacliq} alt="tatacliq" />
          </div>
          <div className="Slidercomp align-middle" style="align-items: center;">
            <img src={cloudtail} alt="cloudtail" />
          </div>
          <div className="Slidercomp align-middle" style="align-items: center;">
            <img src={nyka} alt="nyka" />
          </div>
          <div className="Slidercomp align-middle" style="align-items: center;">
            <img src={meesho} alt="meesho" />
          </div>
          <div className="Slidercomp align-middle" style="align-items: center;">
            <img src={fynd} alt="fynd" />
          </div>
          <div
            className=" Slidercomp align-middle"
            style="align-items: center;"
          >
            <img src={bijnis} />
          </div>
          <div
            className=" Slidercomp my-auto align-middle"
            style="align-items: center;"
          >
            <img src={firstcry} />
          </div>
        </Slider>
      </div>
    );
  }
}
