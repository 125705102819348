import React ,{Component}from 'react';
import Carousel from 'react-bootstrap/Carousel'  
import ecom1 from '../assets/img/business/EcommerceServicesLebenswerkBusinessManagement1.png';
import ecom2 from '../assets/img/business/EcommerceServicesLebenswerkBusinessManagement2.png';
import ecom3 from '../assets/img/business/EcommerceServicesLebenswerkBusinessManagement3.png';
import ecom4 from '../assets/img/business/EcommerceServicesLebenswerkBusinessManagement4.png';
import ecom5 from '../assets/img/business/EcommerceServicesLebenswerkBusinessManagement5.png';
import ecom6 from '../assets/img/business/EcommerceServicesLebenswerkBusinessManagement6.png';
import ecom7 from '../assets/img/business/EcommerceServicesLebenswerkBusinessManagement7.png';
import ecom8 from '../assets/img/business/EcommerceServicesLebenswerkBusinessManagement8.png';
import ecom9 from '../assets/img/business/EcommerceServicesLebenswerkBusinessManagement9.png';
import ecom10 from '../assets/img/business/EcommerceServicesLebenswerkBusinessManagement10.png';
import ecom11 from '../assets/img/business/EcommerceServicesLebenswerkBusinessMangement11.png';
import business1 from '../assets/img/business/BusinessConsultingLebenswerkBusinessManagement1.png';
import business2 from '../assets/img/business/BusinessConsultingLebenswerkBusinessManagement2.png';
import business3 from '../assets/img/business/BusinessConsultingLebenswerkBusinessManagement3.png';
import business4 from '../assets/img/business/BusinessConsultingLebenswerkBusinessManagement4.png';
import Allied1 from '../assets/img/business/AlliedServicesLebenswerkBusinessManagement1.png';
import Allied2 from '../assets/img/business/AlliedServicesLebenswerkBusinessManagement2.png';
import HRserv from '../assets/img/business/HRServicesLebenswerkBusinessManagement.png';

class Services extends Component {
  
    render() {  
        return(
     <div class="container-fluid newbg">
     <div class="row row-header services-page justify-content-center">  
           
            <div class="col-10 mt-2 col-sm-8">
                
        <h6 className = "p-2 text-center"> Lebenswerk works closely with the Organizations for overall Business development and Primarily on below 4 Critical Business Domains.</h6> 

            </div>
        <div class="col-10 mt-2 col-sm-8 service-block">

                <Carousel interval={null} keyboard={false} pauseOnHover={true}>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src={ecom1}
                            alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src={ecom2}
                            alt="First slide"
                            />
                        
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src={ecom3}
                            alt="First slide"
                            />
                          
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src={ecom4}
                            alt="First slide"
                            />
                         
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src={ecom5}
                            alt="First slide"
                            />
                           
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src={ecom6}
                            alt="First slide"
                            />
                         
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src={ecom7}
                            alt="First slide"
                            />
                     
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src={ecom8}
                            alt="First slide"
                            />
                        
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src={ecom9}
                            alt="First slide"
                            />
                           
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src={ecom10}
                            alt="First slide"
                            />
                           
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src={ecom11}
                            alt="First slide"
                            />
                  
                        </Carousel.Item>
                        
                </Carousel>
         
       
            </div>
  
            <div class="col-10 mt-2 col-sm-8 service-block">
                          

            <Carousel interval={null} keyboard={false} pauseOnHover={true}>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src={business1}
                            alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src={business2}
                            alt="First slide"
                            />
                        
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src={business3}
                            alt="First slide"
                            />
                          
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src={business4}
                            alt="First slide"
                            />
                         
                        </Carousel.Item>
                        </Carousel>

            </div>
        <div class="col-10 mt-2 col-sm-8 service-block">
                          
           <Carousel interval={null} keyboard={false} pauseOnHover={true}>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src={Allied1}
                            alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src={Allied2}
                            alt="First slide"
                            />
                        </Carousel.Item>
            </Carousel> 
         </div>
         
        <div class="col-10 my-2 col-sm-8 service-block">
       
       <div id="demo" class="carousel slide" data-ride="carousel" data-interval="false">
                                  
        
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src={HRserv} alt="Los Angeles" class="img-fluid" />
              </div>            
            </div>
           
          </div>   
                
             
        </div>
                 


            </div>
        </div>
        )
    }
}

export default Services;