import hiring from "../../assets/img/hiring.jpg";
import joinus from "../../assets/img/joinus.jpg";

const Hcs = () => {
  return (
    <div class="container-fluid">
      <div class="row row-content ams-section">
        <div class="col-12 text-center p-2">
          <h2>We Provide the Finest HR Consultancy Services in Delhi NCR</h2>
          <h5>
            Lebenswerk Consulting is a reputable firm that assists businesses
            through its HR consultancy services in Delhi NCR. We cater to
            clients across all industries and geographical regions. Our team
            comprises diligent, qualified, and skilled executives who possess
            several years of experience in the field of human resource
            management.
          </h5>
        </div>
      </div>

      <div class="row row-content ams-section hr-section ">
        <div class="col-12 text-center p-2">
          <h2>Why Do You Need HR Consultancy Services?</h2>
          <h5>
            Human resource management is an area that you should not ignore. The
            HR or the Human Resource department handles the responsibility of
            recruiting personnel in an enterprise. They carry out a multitude of
            functions ranging from recruitment and induction to on-the-job
            training and performance management. However, not every organization
            has an in-house human resource department. Some of the reasons are:-
          </h5>
          <a href="#reserve" class="btn btn-lg m-2 ">
            ● Lack of Leadership
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ● Lack of Industry Knowledge
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ● Lack of Expertise
          </a>

          <h5>
            We offer practical and cost-effective HR solutions. Our HR
            consultancy services in Delhi NCR include not just recruitment but
            many other tasks as well. We can handle matters related to:-
          </h5>

          <div class="col-12 text-center p-2">
            <a href="#reserve" class="btn btn-lg m-2 ">
              ➢ Human Resource Policy Creation
            </a>
            <a href="#reserve" class="btn btn-lg m-2">
              ➢ Compensation
            </a>
            <a href="#reserve" class="btn btn-lg m-2">
              ➢ Bonus
            </a>
            <a href="#reserve" class="btn btn-lg m-2 ">
              ➢ Non-Monetary Benefits
            </a>
            <a href="#reserve" class="btn btn-lg m-2">
              ➢ Payroll Administration
            </a>
            <a href="#reserve" class="btn btn-lg m-2">
              ➢ Training Programs
            </a>

            <a href="#reserve" class="btn btn-lg m-2 ">
              ➢ Performance Evaluations
            </a>
            <a href="#reserve" class="btn btn-lg m-2">
              ➢ Rewards & Incentives
            </a>
            <a href="#reserve" class="btn btn-lg m-2">
              ➢ Increments
            </a>
            <a href="#reserve" class="btn btn-lg m-2 ">
              ➢ Promotions
            </a>
            <a href="#reserve" class="btn btn-lg m-2">
              ➢ Leadership Programs
            </a>
            <a href="#reserve" class="btn btn-lg m-2 ">
              ➢ Medical Plans
            </a>
            <a href="#reserve" class="btn btn-lg m-2">
              ➢ Exit Interviews
            </a>
          </div>
        </div>
      </div>

      <div class="row row-content ams-section2">
        <div class="col-12 text-center p-2">
          <h2>What All We Do As Human Resource Consultants?</h2>
          <h5>
            Lebenswerk Consulting is a recognized HR Consultant in Delhi that
            provides end-to-end staffing solutions for different industries
            which include:-
          </h5>
        </div>

        <div class="col-sm-6 deals my-auto" id="reservebtn">
          <h3 class="">Policy Framework</h3>

          <p class="mt-2">
            As a part of the human resource policy creation service, we can
            provide our inputs in forming rules regarding employment, induction,
            leave, termination, resignation, retirement, overtime, deductions,
            harassment at the workplace, disciplinary action, and employee
            grievances. We ensure that the employees we hire for your business
            are aware of the local and national laws about labour,
            documentation, certification, and more.
          </p>

          <h3 class="">Hiring & Selection</h3>

          <p class="mt-2">
            We are the preferred human resource consultants for business
            enterprises of various sizes and types. Our professional HR
            consultancy services in Delhi NCR include framing the KRAs and
            explaining the same to the employees as well. But, our
            responsibility doesn’t end when we select a candidate for the
            vacancies in your organization. We educate the newly selected
            employee on the rules and policies applicable to your company and
            region. We assess the knowledge and skills of every applicant and
            conduct an extensive background check before hiring any candidate
            for our clients.
          </p>

          <h3 class="">Friendly Work Environment</h3>

          <p class="mt-2">
            We make sure that you build a strong rapport with your employees to
            gain their trust. Our team has proficiency in providing solutions
            for both hiring and retaining staff members. We also offer
            suggestions to clients on how to create a friendly and productive
            environment in an organization. Our expert executives can also
            contribute towards improving the workflow in your organization and
            lay down rewards and incentive programs, medical plans, etc.
          </p>
        </div>

        <div class="col-sm-6 p-2">
          <div class="row">
            <div class="col p-5">
              <img
                src={hiring}
                alt="HR Consultancy Services"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
        <br />

        <div class="col-sm-6 p-2">
          <div class="row d-none d-sm-block">
            <div class="col p-5">
              <img
                src={joinus}
                alt="HR Consultancy Services"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
        <div class="col-sm-6 deals my-auto" id="reservebtn">
          <h3 class="">Employee Performance Check</h3>

          <p class="mt-2">
            We keep track of employee performance and evaluate their progress
            over time. For this, we define KPIs and compare them with the
            already established KRAs. Then based on the results of the
            evaluation we develop a future course of action. Our team also
            provides insights on the promotions, future role development of
            employees and suggests the positions they can fit in.
          </p>

          <h3 class="">Employee Training Programmes</h3>

          <p class="mt-2">
            Training is an inescapable part of every organization and has to be
            taken care of well. We conduct employee training as per the company
            policies and hierarchy so that they can serve the organizational
            goals better. This ensures their personal growth as well and
            therefore fosters long-term relationships. A well-trained employee
            can serve as a building block for the business.
          </p>

          <h3 class="">Industries We Serve</h3>

          <p class="mt-2">
            Employees are a valuable asset for every business firm. Hiring
            employees is the first step towards building a loyal workforce
            because they play a vital role in the growth of an enterprise.
            Therefore, you must choose the right employees for your
            organization.
          </p>
        </div>
        <br />
      </div>
      <div class="row row-content domain-expert faq-section">
        <div class="col-12 text-center p-5" id="discount-block">
          <h2 className="pb-3">
            Consult Lebenswerk Consulting today to get reliable HR consultancy
            services in Delhi NCR within your budget.
          </h2>
          <h5>
            We offer end-to-end staffing solutions to help organizations meet
            their manpower needs. We can source candidates for various job roles
            across numerous verticals. Our team has hired candidates for clients
            operating in different industries, including but not limited to: -
          </h5>
        </div>

        <div class="col-12 text-center px-5 pb-2 Hcs">
          <a href="#reserve" class="btn btn-lg m-2 ">
            ➢ Food
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ➢ Fashion
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ➢ Wholesale & Retail
          </a>
          <a href="#reserve" class="btn btn-lg m-2 ">
            ➢ Ecommerce
          </a>

          <a href="#reserve" class="btn btn-lg m-2">
            ➢ Professional Salon Business
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ➢ Banking & Finance
          </a>

          <a href="#reserve" class="btn btn-lg m-2 ">
            ➢ Insurance
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ➢ Law
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ➢ Real State
          </a>

          <a href="#reserve" class="btn btn-lg m-2 ">
            ➢ Education
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ➢ Health
          </a>
          <a href="#reserve" class="btn btn-lg m-2 ">
            ➢ Hospitality
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ➢Telecommunications
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ➢ Manufacturing
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ➢ Transportation
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ➢ Tourism
          </a>
          <a href="#reserve" class="btn btn-lg m-2">
            ➢ Non-Profit Organizations.
          </a>
        </div>
      </div>
    </div>
  );
};

export default Hcs;
